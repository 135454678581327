@charset "utf-8";

// Global Reset
$text-primary:  #25c33a;
$background: black;
$comment: rgb(167, 168, 149);
$punctuation: #3dc2ff;
$error: #cc0000;
$error-background: #250f0f;
$keyword: #f3ff33;
$value: #b4c013;
$variable: #d44639;
$data-type: rgb(161, 255, 53);
$class-name: #c23766;
$string: rgb(255, 197, 71);
$package: yellow;
$white-space: #c4ffcc;

.highlight table td { padding: 5px; }
.highlight table pre { margin: 0; }
.highlight {
  word-wrap: break-word;
  color: $text-primary;
  background-color: $background;
}
/* comment */
.highlight .c, .highlight .cd, .highlight .cm, .highlight .c1, .highlight .cs {
  color: $comment;
  font-style: italic;
}
// Preprocessor comments
.highlight .cp {
  color: $keyword;
  font-weight: bold;
  font-style: italic;
}
.highlight .err {
  color: $error;
  background-color: $error-background;
}
.highlight .gr {
  color: $error;
  background-color: $error-background;
}
/* keyword */
.highlight .k, .highlight .kd, .highlight .kv {
  color: $keyword;
  font-weight: bold;
}
.highlight .o, .highlight .ow {
  color: $punctuation;
}
.highlight .p, .highlight .pi {
  color: $punctuation;
}
.highlight .gd {
  color: $error;
}
.highlight .gi {
  color: $text-primary;
}
.highlight .ge {
  font-style: italic;
}
.highlight .gs {
  font-weight: bold;
}
.highlight .gt {
  color: $error;
}
// line numbers
.highlight .gl {
  color: $background;
  background: text-primary;
}
.highlight .kc {
  color: $value;
  font-weight: bold;
}
.highlight .kn {
  color: $package;
  font-weight: bold;
}
.highlight .kp {
  color: $package;
  font-weight: bold;
}
.highlight .kr {
  color: $package;
  font-weight: bold;
}
.highlight .gh {
  color: $package;
  font-weight: bold;
}
.highlight .gu {
  color: $package;
  font-weight: bold;
}
.highlight .kt {
  color: $data-type;
  font-weight: bold;
}
.highlight .no {
  color: $data-type;
  font-weight: bold;
}
.highlight .nc {
  color: $class-name;
  font-weight: bold;
}
.highlight .nd {
  color: $class-name;
  font-weight: bold;
}
.highlight .nn {
  color: $class-name;
  font-weight: bold;
}
.highlight .bp {
  color: $class-name;
  font-weight: bold;
}
.highlight .ne {
  color: $class-name;
  font-weight: bold;
}
.highlight .nl {
  color: $value;
  font-weight: bold;
}
.highlight .nt {
  color: $value;
  font-weight: bold;
}
.highlight .m, .highlight .mf, .highlight .mh, .highlight .mi, .highlight .il, .highlight .mo, .highlight .mb, .highlight .mx {
  color: $value;
  font-weight: bold;
}
.highlight .ld {
  color: $value;
  font-weight: bold;
}
.highlight .ss {
  color: $value;
  font-weight: bold;
}
.highlight .s, .highlight .sb, .highlight .sd, .highlight .s2, .highlight .sh, .highlight .sx, .highlight .sr, .highlight .s1 {
  color: $string;
  font-weight: bold;
}
.highlight .se {
  color: $data-type;
  font-weight: bold;
}
.highlight .sc {
  color: $data-type;
  font-weight: bold;
}
.highlight .si {
  color: $data-type;
  font-weight: bold;
}
.highlight .nb {
  font-weight: bold;
}
// Token for entitites such as &nbsp; in HTML
.highlight .ni {
  color: $value;
  font-weight: bold;
}
// specially highlighted whitespace
.highlight .w {
  color: $white-space;
}
/* funtion delaration */
.highlight .nf {
    color: $text-primary;
    font-weight: bold;
}
.highlight .py {
  color: $variable;
}
.highlight .na {
  color: $text-primary;
}
.highlight .nv, .highlight .vc, .highlight .vg, .highlight .vi {
  color: $variable;
  font-weight: bold;
}
